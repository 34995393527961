import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "contactInfoErrorMessage",
    "email",
    "emailErrorMessage",
    "phoneNumber",
    "phoneNumberErrorMessage",
    "submitButton",
  ];

  connect() {
    this.phoneNumberTarget.addEventListener(
      "input",
      this.validateForm.bind(this),
    );
    this.emailTarget.addEventListener(
      "input",
      this.validateForm.bind(this)
    );
  }

  validateForm = () => {
    const phoneNumber = this.normalizePhoneNumber(this.phoneNumberTarget.value);
    const email = this.emailTarget.value;

    let phoneNumberValid = this.validatePhoneNumber(phoneNumber);
    let emailValid = this.validateEmail(email);
    let emailOrPhoneNumberPresent = this.validateEmailOrPhoneNumberPresent(email, phoneNumber);

    if (phoneNumberValid && emailValid && emailOrPhoneNumberPresent) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  };

  validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{10,}$/;

    if (phoneNumber.length > 0 && !phoneNumber.match(phoneNumberRegex)) {
      this.showErrors(this.phoneNumberErrorMessageTarget, "Invalid phone number. Phone numbers must be ten digits with area code.");
      return false;
    } else {
      this.hideErrors(this.phoneNumberErrorMessageTarget)
      return true;
    }
  }

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email.length > 0 && !email.match(emailRegex)) {
      this.showErrors(this.emailErrorMessageTarget, "Invalid email address.");
      return false;
    } else {
      this.hideErrors(this.emailErrorMessageTarget)
      return true;
    }
  }

  validateEmailOrPhoneNumberPresent = (email, phoneNumber) => {
    if (phoneNumber.length === 0 && email.length === 0) {
      this.showErrors(this.contactInfoErrorMessageTarget, "Either email or phone number must be present.");
      return false;
    } else {
      this.hideErrors(this.contactInfoErrorMessageTarget);
      return true;
    }
  }

  showErrors = (target, message) => {
    target.classList.remove("d-none");
    target.textContent = message;
  }

  hideErrors = (target) => {
    target.classList.add("d-none");
    target.textContent = "";
  }

  normalizePhoneNumber = (input) => {
    const digits = input.replace(/\D/g, "");

    if (digits.length === 11 && digits.startsWith("1")) {
      return digits.slice(1);
    } else {
      return digits;
    }
  };
}
